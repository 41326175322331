/* MENU OPEN AND CLOSE */
var hamburger = $(".menuBtn");
$(hamburger).on('click', function(e) {
	e.preventDefault();

	$(hamburger).each(function() {
		$(this).toggleClass('act');
	});
	if($(this).hasClass('act')) {
		$('.main-navigation').addClass('act');

		setTimeout(function() {
			//$('.main-navigation').css('overflow-y', 'auto');
			$('.main-navigation').each(function () {
				this.style.setProperty( 'overflow-y', 'auto', 'important' );
			});
		}, 300)
	}
	else {
		//$('.main-navigation').removeClass('act').css('overflow-y', 'hidden');
		$('.main-navigation').removeClass('act');
		$('.main-navigation').each(function () {
			this.style.setProperty( 'overflow', 'hidden' );
		});
	}
});

/*
$(document).click(function(event) {
    console.log('here');
  var $target = $(event.target);
  if($('.main-navigation').hasClass('act')) {
    $('.main-navigation').removeClass('act').css('overflow-y', 'hidden');
  }
});
*/

$(".nav-container").on('hidden.bs.collapse', function () {
  $("body").removeClass("menu-active");
});
$(".nav-container").on('show.bs.collapse', function () {
  	$("body").addClass("menu-active");
});

export function hideOnClickOutside(selector) {
  const outsideClickListener = (event) => {

    const $target = $(event.target);
    if (!$target.closest(selector).length && $(selector).hasClass('act')) {
		$(hamburger).trigger("click");
    }
  }
  const removeClickListener = () => {
    document.removeEventListener('click', outsideClickListener)
  }
  document.addEventListener('click', outsideClickListener)
}
hideOnClickOutside('.main-navigation');