window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.$ = window.jQuery = require('jquery');

} catch (e) {}

//require('bootstrap-transition');
//require('bootstrap-collapse');
//require('bootstrap-scrollspy');

require('bootstrap');

var gHamburger = require('./plugins/google-hamburger');
var bootstrapDropdown = require('./plugins/bootstrap-dropdown');
require('./vendor/salvattore.min.js');

require('./libs/jquery.sticky');
$(".navbar").sticky({topSpacing:0, zIndex:98});
$(".main-navigation").sticky({topSpacing:0, zIndex:99, widthFromWrapper:false});
$(".festival-sidebar--menu").sticky({topSpacing:75, zIndex:99, widthFromWrapper:false});

// mobile menu subpages
/**/
$(window).on('load resize', function(){
  if(window.innerWidth <= 768) {
    $(".navbar-nav > li.dropdown > a").on('click', function(e) {
        e.preventDefault();
        $dropdownList = $(this).parent(".dropdown").children(".dropdown-menu");
        $dropdownParent = $(this).parent(".dropdown");

        $dropdownParent.toggleClass("show");
        $dropdownList.toggleClass("show");
    })
  }
})
/**/

// Trigger Search bar
$('.search-button').on('click', function(){

	var searchBar = $('.search-area');
	searchBar.addClass("active");

	$(".search-input").focus();

	// Add backdrop
	$('body').append($('<div>', {class: 'modal-backdrop fade show'}));

	searchBar.find('.close').on('click', function(){
		searchBar.removeClass("active");
		$('.modal-backdrop').remove();
		return false;
	});
	return false;
});

// Plus To Minus in FAQ / Accordian
$('.card-header .btn').on('click', function(){

  	$(".card-header .btn").not(this).removeClass('active');
  	$(".card-header .btn").not(this).closest(".card-header").find(".plus-to-minus").removeClass('active');

  	$(this).toggleClass('active');
  	$(this).closest(".card-header").find(".plus-to-minus").toggleClass('active');
});

//Sticky page nav
//Changing label of dropdown collapse to clicked link
$("#festival-sidebar-menu-sticky-wrapper .navbar-toggler").on('click', function() {
  $stickyNavbar = $(this).parents("#festival-sidebar-menu-sticky-wrapper");


	  if($stickyNavbar.hasClass("js-sticky-navbar-collapsed")) {
		$(this).toggleClass('d-none');
	  }
    // Close dropdown
    $('.navbar-toggler .navbar-collapse').collapse('hide');
});

$("#festival-sidebar-menu-sticky-wrapper .navbar-collapse .list-group-item").on('click', function() {
  var $stickyNavbar = $(this).parents("#festival-sidebar-menu-sticky-wrapper");
  var $clickedNavLink = $(this);
  var $navbarCollapsed = $clickedNavLink.parents(".navbar-collapse");
  var $navbarToggle = $navbarCollapsed.siblings(".navbar-toggler");

  if($stickyNavbar.hasClass("js-sticky-navbar-collapsed")) {
    $navbarToggle.find(".nav-item").text($clickedNavLink.text());

    $navbarCollapsed.collapse('hide');
    $navbarToggle.toggleClass('d-none');
  }

    $('html, body').animate({
        scrollTop: $($clickedNavLink.attr('href')).offset().top,
    }, 500);
});

//added class to be used for checking if sticky nav is collapsed
$(window).on('load resize', function(){
  if(window.innerWidth <= 1200) {
    $("#festival-sidebar-menu-sticky-wrapper").addClass("js-sticky-navbar-collapsed");
  } else {
    $("#festival-sidebar-menu-sticky-wrapper").removeClass("js-sticky-navbar-collapsed");
    $(".dropdown-menu.show").removeClass("show");
  }
});

require('./jquery/jquery.magnific-popup.min');
/**/
$('.menu-video').magnificPopup({
    type: 'iframe',
    iframe: {
      markup: '<div class="mfp-iframe-scaler">'+
                '<div class="mfp-close"></div>'+
                '<iframe class="mfp-iframe" allow="autoplay" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'+
              '</div>',

      patterns: {
        youtube: {
          index: 'youtube.com/',
          id: 'v=',
          src: '//www.youtube.com/embed/%id%?autoplay=1'
        },
        vimeo: {
          index: 'vimeo.com/',
          id: '/',
          src: '//player.vimeo.com/video/%id%?autoplay=1'
        },
        gmaps: {
          index: '//maps.google.',
          src: '%id%&output=embed'
        }
      },
      srcAction: 'iframe_src',
    },
    callbacks: {
        open: function() {},
        close: function() {
          //hideOnClickOutside('.main-navigation');
        }
    }
});
/**/

// Open targeted links in new window
/*
function openInNewWindow(href, parentClass = '') {
	$(parentClass+' a[href="'+href+'"]').click(function(){
		window.open(this.href);
		return false;
	});
}
openInNewWindow("/innovation", ".footer-menu-section");
*/



//require('./libs/jquery.instashow.packaged');

// Slider
//require('slick-carousel');
//$('.slider').slick();